<template>
    <div>
        <label :for="id" :class="labelClass" v-if="label">{{ label }}</label>
        <div v-if="maxFlag">{{maxMsg || "You cannot exceed  "+ $getCurrency(max)}}</div>
        <input
            :id="id"
            type="text"
            :value="value"
            :disabled="disabled"
            @input="handleInput"
            @focus="handleFocus"
            :key="inputKey"
            ref="currencyInput"
            style="text-align: right"
        />
    </div>
</template>

<script>
    export default {
        name: 'CurrencyInput2',
        props: {
            label: {
                type: String,
                required: false
            },
            labelClass: {
                type: String,
                required: false
            },
            id: {
                type: String,
                default: 'currency-input-default-id'
            },
            initialValue: {
                type: Number,
                default: 0
            },
            disabled: {
                type: Boolean,
                default: false
            },
            max: {
                type: Number
            },
            maxMsg: {type: String}
        },
        data() {
            return {
                value: '',
                numbers: [],
                inputKey: 0,
                rerendering: false,
                inputTouched: false,
                maxFlag: false
            };
        },
        methods: {
            init() {
                this.value = this.initialValue ? this.$getCurrency(this.initialValue) : this.$getCurrency(0);

                if (this.initialValue) {
                    this.numbers = this.initialValue.toString().split('');
                } else {
                    this.numbers = ['0', '0', '0'];
                }
            },
            handleFocus(e) {
                if (!this.rerendering) {
                    e.target.select();
                }

                this.inputTouched = true;
            },
            async handleInput(e) {
                if (!e.target.value) {
                    this.value = this.$getCurrency(0);
                    return;
                }

                let newNum = this.$getNumFromCurrency(e.target.value);

                if (isNaN(newNum)) {
                    this.value = this.$getCurrency(0);
                    return;
                }

                if (typeof this.max === 'number'  && newNum > this.max){
                   newNum = this.max;
                   this.maxFlag = true;
                } else {
                    this.maxFlag = false;
                }

                const newValue = this.$getCurrency(newNum);

                if (newValue === this.value) {
                    this.value = newValue;
                    this.inputKey += 1;
                    this.rerendering = true;
                    await this.$nextTick();
                    this.$refs.currencyInput.focus();
                    this.rerendering = false;
                } else {
                    this.value = newValue;
                }
            },
            clear() {
                this.value = this.$getCurrency(0);
            }
        },
        created() {
            this.init();
        },
        watch: {
            initialValue() {
                this.init();
            },
            value() {
                if (this.inputTouched) {
                    this.$emit('currency', this.value);
                }
            }
        }
    };
</script>

<style scoped></style>
