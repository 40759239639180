<template>
    <div class="custom-list-grid-standard">
        <div class="single-setting" v-for="(datum, ndx) in store_vals_here" :key="datum.id">
            <h3 v-if="datum.header" v-html="datum.header"></h3>
            <div class="subheader bottom-10" v-html="datum.subheader"></div>
            <div class="pad-l-10">
       
                <Toggle
                    v-if="datum.data_type == 'boolean'"
                    :id="datum.setting"
                    :name="datum.setting"
                    :label="datum.label"
                    :value="store_vals_here[ndx].value"
                    @input="update_setting($event, datum, ndx)"
                    v-model="store_vals_here[ndx].value"
                    class="flex center"
                />
                <div   v-else-if="datum.data_type==SettingsOptionType.TEXT_MULTI_SELECT">
                    <label :for="datum.setting">{{ datum.label }}</label>
                    <Multiselect
                        class="left-5"
                        :id="datum.setting"
                        :name="datum.setting"
                        :options="arrayme(datum.value_options)"
                        @input="debounceUpdateSetting($event, datum, ndx)"
                        :disableEmpty="!!datum.no_empty"
                        v-model="store_vals_here[ndx].value"
                        :taggable="true"
                        :multiple="true"
                        label="text"
                        track-by="value"
                        :searchable="true"
                    />
                </div>
                <Dropdown
                    class="left-5"
                    v-else-if="datum.value_options"
                    :id="datum.setting"
                    :name="datum.setting"
                    :label="datum.label"
                    :options="arrayme(datum.value_options)"
                    @input="update_setting($event, datum, ndx)"
                    :disableEmpty="!!datum.no_empty"
                    v-model="store_vals_here[ndx].value"
                />
                <TextArea
                    v-else-if="datum.data_type == 'textarea'"
                    type="text"
                    :name="datum.setting"
                    :id="datum.setting"
                    :placeholder="datum.label"
                    :label="datum.label"
                    @input="debounceUpdateSetting($event, datum, ndx)"
                    v-model="store_vals_here[ndx].value"
                />
                <CurrencyInput
                    v-else-if="datum.data_type == 'currency'"
                    @currency="debounceCurrencyChange($event, datum, ndx)"
                    :placeholder="datum.label"
                    :label="datum.label"
                    :id="datum.setting"
                    :name="datum.setting"
                    :key="datum.setting"
                    :initialValue="getNumber(store_vals_here[ndx].value)"
                />
                <Input
                    v-else
                    type="text"
                    :name="datum.setting"
                    :id="datum.setting"
                    :placeholder="datum.label"
                    :label="datum.label"
                    @input="debounceUpdateSetting($event, datum, ndx)"
                    v-model="store_vals_here[ndx].value"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import CurrencyInput from '@/components/general/inputs/CurrencyInput';
    import { SettingsOptionType } from '@/util/globalConstants'
    import { debounce } from 'lodash';

    const api_root = 'settings';
    export default {
        components: { CurrencyInput },
        name: 'ChirpSettings',
        props: {
            level: {
                type: String,
            },
            level_id: {
                type: Number,
                default: 1,
            },
            data_source: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                toolbar_options: ['Search'],
                store_vals_here: [],
                loading: 1,
                multi_dd_value:null,
                SettingsOptionType
            };
        },
        methods: {
            arrayme(looper) {
                // this.store_vals_here = this.data_source
                if (looper) {
                    try {
                        looper = JSON.parse(looper);
                    } catch (e) {
                        looper = [];
                    }
                } else {
                    looper = [];
                }
                return looper;
            },
            async validate() {
                return 1;
            },
            debounceCurrencyChange: debounce(function (val, datum, ndx) {
                this.update_setting(this.$getNumFromCurrency(val), datum, ndx);
            }, 400),
            debounceUpdateSetting: debounce(function (val, datum, ndx) {
                this.update_setting(val, datum, ndx);
            }, 400),
            async update_setting(val, datum, ndx) {
                // this.data_source[ndx].value = val
                this.store_vals_here[ndx].value = val;

                const is_valid = await this.validate();
                // const res = null

                if (is_valid) {
                    try {
                        if (datum.data_id) {
                            // update existing settings_data value
                            await this.$api.put(`/${api_root}/${datum.data_id}`, { val: datum.data_type===SettingsOptionType.TEXT_MULTI_SELECT?JSON.stringify(val):val });
                        } else {
                            // create settings_data record
                            const new_rec = await this.$api.post(
                                `/${api_root}/${this.level}/${this.level_id}/${datum.settings_id}`,
                                { val: datum.data_type===SettingsOptionType.TEXT_MULTI_SELECT?JSON.stringify(val):val  }
                            );
                            // need to update datum.data_id or it will keep making more records
                            this.store_vals_here[ndx].data_id = new_rec.data.recId[0];
                        }
                        // this.$cl(res)
                        this.$toasted.success('Successfully updated Record.');
                    } catch (err) {
                        //this.$toasted.error('Failed to update Record. Please try again later.');
                    }
                }
            },
            getNumber(val) {
                if (typeof val === 'number') {
                    return val;
                }

                if (typeof val === 'string') {
                    return parseInt(val);
                }

                return 0;
            },
        },

        async created() {
            
            this.store_vals_here = this.data_source.map((item)=>{
                if(item.data_type==='text_multi-select'){
                    item.value=JSON.parse(item.value)
                }
                return item
            });
      
        },
    };
</script>
